.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: "#906B7F";
  color: "#D9CAD3";
  min-height: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pinky {
  background-color: #906B7F !important;
  color: #D9CAD3 !important;
}

.pinky:hover {
  background-color: #D9CAD3 !important;
  color: #906B7F !important;
}

.pinky-white {
  background-color: #906B7F !important;
  color: white !important;
}

.pinky-white:hover {
  background-color: #D9CAD3 !important;
  color: #906B7F !important;
}

.border-corner {
  padding: 1rem;
  background:
    linear-gradient(to right, gray 1px, transparent 1px) 0 0,
    linear-gradient(to right, gray 1px, transparent 1px) 0 100%,
    linear-gradient(to left, gray 1px, transparent 1px) 100% 0,
    linear-gradient(to left, gray 1px, transparent 1px) 100% 100%,
    linear-gradient(to bottom, gray 1px, transparent 1px) 0 0,
    linear-gradient(to bottom, gray 1px, transparent 1px) 100% 0,
    linear-gradient(to top, gray 1px, transparent 1px) 0 100%,
    linear-gradient(to top, gray 1px, transparent 1px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}